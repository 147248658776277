let url = "";
let google_api_key = "";
let urlSSORumo = '';

if (process.env.NODE_ENV === 'development') {
	url = process.env.REACT_APP_URL;
	google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;
	urlSSORumo = process.env.REACT_APP_URL_SSO_RUMO;
} else {
	if (process.env.REACT_APP_AMBIENTE === 'PRD') {
		url = "https://api.tracking.kmm.com.br";
		google_api_key = process.env.REACT_APP_GOOGLEAPIKEY;
		urlSSORumo = 'https://sso.ops.ti.rumolog.com/auth/realms/rumo/protocol/openid-connect/auth?client_id=tracking-kmm&redirect_uri=http://tracking.kmm.com.br/auth/sso-rumo&response_type=code&scope=openid%20email%20profile%20roles%20offline_access&state=8120781d4f15439d80a0edce0443bd2d&code_challenge=9sJ0M4W2MEzmR7k2hH5aKa9AtYqZDi8RzP0ffa815LA&code_challenge_method=S256&response_mode=query';
	}
	if (process.env.REACT_APP_AMBIENTE === 'HML') {
		url = "https://hml.api.tracking.kmm.com.br";
		google_api_key = process.env.REACT_APP_GOOGLEAPIKEY;
		urlSSORumo = 'https://sso.ops.ti.rumolog.com/auth/realms/rumo/protocol/openid-connect/auth?client_id=tracking-kmm&redirect_uri=http://tracking.kmm.com.br/auth/sso-rumo&response_type=code&scope=openid%20email%20profile%20roles%20offline_access&state=8120781d4f15439d80a0edce0443bd2d&code_challenge=9sJ0M4W2MEzmR7k2hH5aKa9AtYqZDi8RzP0ffa815LA&code_challenge_method=S256&response_mode=query';
	}
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/web",
	google_api_key,
	urlSSORumo
};
