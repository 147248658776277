
import axios from "axios";
import { Constants } from '../utils/Constants';

export function get() {
	return axios.get(`${Constants.baseUrl}/empresa`);
}

export function update(form) {
	return axios.post(`${Constants.baseUrl}/empresa`, form);
}
