/* eslint no-useless-escape: 0 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from '../../components/BetterBox';
import BetterAlerts from '../../components/BetterAlerts';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { reset } from '../../crud/auth.crud';
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import queryString from 'query-string';

class ResetPassword extends Component {
	constructor() {
		super();

		this.state = {
			token: '',
			email: '',
			password: '',
			confirm: '',
			loading: false,
			submitted: false,
		};
	}

	componentDidMount = () => {
		let params = queryString.parse(this.props.location.search);
		this.setState({ token: params.token, email: params.email });
	};

	onSubmit = () => {
		this.setState(({ submitted: true }));

		if (this.state.password !== this.state.confirm) {
			Swal.fire('Ops!', 'As senhas não são iguais', 'error');
			this.setState(({ submitted: false }));
			return;
		}

		if (this.state.password.length < 8) {
			Swal.fire('Ops!', 'Sua nova senha precisa ter no mínimo 8 caracteres.', 'error');
			this.setState(({ submitted: false }));
			return;
		}

		if (!(/\d/.test(this.state.password) && /[a-zA-Z]/.test(this.state.password))) {
			Swal.fire('Ops!', 'Sua senha precisa possuir letras e números.', 'error');
			this.setState(({ submitted: false }));
			return;
		}

		if (!(/[A-Z]/.test(this.state.password))) {
			Swal.fire('Ops!', 'Sua senha precisa possuir pelo menos uma letra em caixa alta.', 'error');
			this.setState(({ submitted: false }));
			return;
		}

		if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.password)) {
			Swal.fire('Ops!', 'Sua senha precisa possuir pelo menos um caracter especial.', 'error');
			this.setState(({ submitted: false }));
			return;
		}

		reset({ password: this.state.password, password_confirmation: this.state.confirm, token: this.state.token, email: this.state.email }).then(async (res) => {
			window.scrollTo(0, 0);

			await Swal.fire({
				icon: 'success',
				title: 'Tudo certo!',
				text: 'Sua senha foi redefinida com sucesso.',
				showConfirmButton: false,
				timer: 1500
			});

			window.location.href = '/auth/login';
		}).catch((err) => {
			if (err.response?.status === 422) {
				Swal.fire('Ops!', err.response.data.errors[0], 'error');
			} else {
				Swal.fire('Ops!', 'Ocorreu um erro ao recuperar senha.', 'error');
			}

			this.setState({ submitted: false });
		});
	};

	render() {

		return (
			<div style={{ margin: '30px 0px 30px 0px' }}>
				<div className="row" style={{ margin: '0' }}>
					<div className="col-sm-12 col-md-8 offset-md-2 col-lg-5 offset-lg-4">
						<div className="row">
							<div className="col-sm-12">
								<center>
									<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: '250px', height: 'auto', marginTop: '30px', marginBottom: '35px' }} />

									<p style={{ padding: '10px 50px 15px 50px', fontSize: '18px', color: '#424242', fontWeight: '500' }}>
										<b>Olá!</b> Para recuperar seu acesso, digite a nova senha.
									</p>
								</center>
							</div>

							<div className="col-sm-12">
								<BetterCircularProgress loading={this.state.loading}>
									<div>
										<div className="col-sm-12">
											<BetterAlerts errors={this.state.errors} />
										</div>

										<div>
											<div className="col-sm-12">
												<BetterBox title="Digite sua nova senha">
													<div className="col-sm-12">
														<div className='form-group fg-line'>
															<TextField type="password" name="password" label="Senha *" margin="normal" variant="outlined"
																onBlur={(event) => this.setState({ password: event.target.value })} onChange={(event) => this.setState({ password: event.target.value })}
															/>
															<TextField type="password" name="confirm" label="Confirmar senha *" margin="normal" variant="outlined"
																onBlur={(event) => this.setState({ confirm: event.target.value })} onChange={(event) => this.setState({ confirm: event.target.value })}
															/>
														</div>
													</div>
												</BetterBox>
											</div>

											<div className="col-sm-12">
												<center>
													<br />
													<Button className="btn btn-primary btn-lg btn-bold" onClick={() => this.onSubmit()}>
														<i className={`margin-icon ${this.state.submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Enviar!
													</Button>

													<br /><br />

													<Link to="/auth/login" className="btn btn-label-brand btn-sm btn-bold">Voltar</Link>
												</center>
											</div>
										</div>
									</div>
								</BetterCircularProgress>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(null, null)(ResetPassword)