import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(data) {
	return axios.post(`${Constants.baseUrl}/auth`, data);
}

export function logout() {
	return axios.get(`${Constants.baseUrl}/logout`);
}

export function forgot(form) {
	return axios.post(`${Constants.baseUrl}/forgot-password`, form);
}

export function reset(form) {
	return axios.post(`${Constants.baseUrl}/reset-password`, form);
}