import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getQuantidadeNaoLida } from "../../../app/crud/notificacao.crud";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { get as getLogo } from '../../../app/crud/empresa.crud';


export default function Topbar() {
	
	const [quantidadeNotificacao, setQuantidadeNotificacao] = useState(0);
	const [logo, setLogo] = useState('');

	const router = useLocation();

	useEffect(() => {
		getQuantidadeNaoLida().then(async (res) => {
			setQuantidadeNotificacao(res.data);
		});

		getLogo().then((res) => {
				setLogo(res.data.logotipo);
		});
	}, [router]);

	return (
		<div className="kt-header__topbar flex-bet">
				{logo !== '' && 
					<img 
						alt="Logo Empresa" 
						src={logo} 
						className="logo-empresa-header-mob"
					/>
				}
				<UserProfile showAvatar={true} showHi={true} showBadge={false} />

				<Link className={quantidadeNotificacao > 0 ? 'header-notification-active' : 'header-notification'} to="/notificacao">
					<i className="fas fa-bell"></i>
					<div className="count-notification">{quantidadeNotificacao}</div>
				</Link>

		</div>
	);
}
