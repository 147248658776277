export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Tracking",
				root: true,
				icon: "fas fa-subway",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Painel de Trânsito",
				root: true,
				icon: "fas fa-chart-line",
				page: "transito",
				bullet: "dot",
				submenu: [
					{
						title: "Controle Fila",
						bullet: "dot",
						page: "transito/controle-fila"
					},
					{
						title: "Gráficos",
						bullet: "dot",
						page: "transito/graficos"
					},
					{
						title: "Previsões",
						bullet: "dot",
						page: "transito/previsoes"
					},
					{
						title: "Extrator - Trem",
						bullet: "dot",
						page: "transito/extrator-comp"
					},
					{
						title: "Extrator - Terminal",
						bullet: "dot",
						page: "transito/extrator-term"
					}
				]
			},
			{
				title: "Faturamento 2.0",
				root: true,
				icon: "fas fa-file-invoice-dollar",
				page: "faturamento",
				bullet: "dot"
			},
			{
				title: "Notificações",
				root: true,
				icon: "fas fa-bell",
				page: "notificacao",
				bullet: "dot"
			},
			{
				title: "Caminhões",
				root: true,
				icon: "fas fa-truck",
				page: "agendamento-caminhao",
				bullet: "dot"
			},
			{
				title: "Cadastros",
				root: true,
				bullet: "dot",
				icon: "fas fa-edit",
				submenu: [
					{
						title: "Estações/Pátios",
						bullet: "dot",
						page: "estacao"
					},
					{
						title: "Terminais",
						bullet: "dot",
						page: "terminal"
					}
				]
			},
			{
				title: "Produtos",
				root: true,
				icon: "fas fa-dolly",
				bullet: "dot",
				submenu: [
					{
						title: "Produtos",
						bullet: "dot",
						page: "produto"
					},
					{
						title: "Grupo de Produtos",
						bullet: "dot",
						page: "grupo-produto"
					}
				]
			},
			{
				title: "Clientes",
				root: true,
				icon: "fas fa-users",
				bullet: "dot",
				submenu: [
					{
						title: "Clientes",
						bullet: "dot",
						page: "cliente"
					},
					{
						title: "Grupo de Clientes",
						bullet: "dot",
						page: "grupo-cliente"
					}
				]
			},
			{
				title: "Acessos",
				root: true,
				bullet: "dot",
				icon: "fas fa-layer-group",
				submenu: [
					{
						title: "Usuários",
						bullet: "dot",
						page: "usuario"
					},
					{
						title: "Grupo de Permissões",
						bullet: "dot",
						page: "grupo-permissao"
					},
					{
						title: "Empresa",
						bullet: "dot",
						page: "empresa"
					}
				]
			}
		]
	}
};
