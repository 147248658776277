import React, { useEffect, useState } from 'react';

export default function ButtonPWA(props) {
	const [installable, setInstallable] = useState(false);
	const [promptInstall, setPromptInstall] = useState(null);

	useEffect(() => {
		const handler = e => {
			e.preventDefault();
			setInstallable(true);
			setPromptInstall(e);
		};

		window.addEventListener('beforeinstallprompt', handler);
		window.addEventListener('appinstalled', () => setInstallable(false));

		const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

		if (document.referrer.startsWith('android-app://') || navigator.standalone || isStandalone) {
			setInstallable(false);
		}

		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const installPWA = (e) => {
		e.preventDefault();

		if (!promptInstall) return;

		promptInstall.prompt();
	};

	if (!installable) return (<></>);

	return (
		<button className="button-pwa" onClick={installPWA}>
			<span style={{ color: props.color ?? '#000' }}>INSTALAR</span>
			<i className="fas fa-download" style={{ color: props.color ?? '#000' }}></i>
		</button>
	);
}