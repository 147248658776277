import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GrupoPermissaoPage = lazy(() =>
	import("./grupo-permissao/GrupoPermissaoPage")
);

const EmpresaPage = lazy(() =>
	import("./empresa/EmpresaPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const FaturamentoPage = lazy(() =>
	import("./faturamento/FaturamentoPage")
);

const EstacaoPage = lazy(() =>
	import("./estacao/EstacaoPage")
);

const TerminalPage = lazy(() =>
	import("./terminal/TerminalPage")
);

const TransitoPage = lazy(() =>
	import("./transito/TransitoPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const GrupoClientePage = lazy(() =>
	import("./grupo-cliente/GrupoClientePage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const NotificacaoPage = lazy(() =>
	import("./notificacao/NotificacaoPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const GrupoProdutoPage = lazy(() =>
	import("./grupo-produto/GrupoProdutoPage")
);

const AgendamentoCaminhaoPage = lazy(() =>
	import("./agendamento-caminhao/AgendamentoCaminhaoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/grupo-permissao" component={GrupoPermissaoPage} />
				<Route path="/empresa" component={EmpresaPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/transito" component={TransitoPage} />
				<Route path="/faturamento" component={FaturamentoPage} />
				<Route path="/estacao" component={EstacaoPage} />
				<Route path="/terminal" component={TerminalPage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/grupo-cliente" component={GrupoClientePage} />
				<Route path="/produto" component={ProdutoPage} />
				<Route path="/grupo-produto" component={GrupoProdutoPage} />
				<Route path="/perfil" component={PerfilPage} />
				<Route path="/notificacao" component={NotificacaoPage} />
				<Route path="/agendamento-caminhao" component={AgendamentoCaminhaoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
