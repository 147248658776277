import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import * as utils from "../../../_metronic/utils/utils";
import { LayoutSplashScreen } from "../../../_metronic";
import Swal from 'sweetalert2';

function SSORumo(props) {
	useEffect(() => {
		let params = new URLSearchParams(window.location.search);

		if (params.get('state') && params.get('code') && params.get('session_state')) {
			onLogin(params.get('state'), params.get('code'), params.get('session_state'));
		} else {
			window.location.href = '/auth';
		}

		// eslint-disable-next-line
	}, []);

	const onLogin = (state, code, session_state) => {
		login({ state, code, session_state }).then(res => {
			utils.setStorage('authToken', res.data.token, null);
			props.login(res.data);
		}).catch(async (err) => {
			if (err.response?.data?.errors) {
				await Swal.fire('Ops!', err.response.data.errors[0], 'error');
			} else {
				await Swal.fire('Ops!', 'Ocorreu algum erro ao autenticar com o SSO Rumo, entre em contato com o suporte.', 'error');
			}

			window.location.href = '/auth';
		});
	}

	return <LayoutSplashScreen visible={true} />
}

export default connect(null, auth.actions)(SSORumo)