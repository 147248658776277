
import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
	return axios.post(`${Constants.baseUrl}/usuario/list`, events);
}

export function create(form) {
	return axios.post(`${Constants.baseUrl}/usuario`, form);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/usuario/${id}`);
}

export function getPerfil() {
	return axios.get(`${Constants.baseUrl}/usuario`);
}

export function update(form, id) {
	return axios.post(`${Constants.baseUrl}/usuario/${id}`, form);
}

export function updatePerfil(form) {
	return axios.post(`${Constants.baseUrl}/usuario/perfil`, form);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/usuario/${id}`);
}