import axios from "axios";
import { Constants } from '../utils/Constants';

export function getList(event) {
	return axios.post(`${Constants.baseUrl}/notificacao/list`, event);
}

export function getListTipos() {
	return axios.get(`${Constants.baseUrl}/notificacao/listTipos`);
};

export function getQuantidadeNaoLida() {
	return axios.get(`${Constants.baseUrl}/notificacao/quantidade-nao-lida`);
};

export function getTiposNotificacoes() {
	return axios.get(`${Constants.baseUrl}/notificacao/tipos`);
};
