import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";

export function ErrorPage3() {
	return (
		<>
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v3"
					style={{
						backgroundImage: `url(${toAbsoluteUrl(
							"/media/error/bg3.jpg"
						)})`
					}}>

					<div className="kt-error_container" style={{ marginTop: 100 }}>
						<p className="kt-error_title" style={{ marginBottom: 30, fontSize: 50 }}>Não autorizado</p>

						<p className="kt-error_title">Você não tem a permissão necessária para <br /> executar essa função.</p>

						<p className="kt-error_subtitle" style={{ marginBottom: 60 }}>Para ter as permissões necessárias fale com o administrador.</p>

						<a href="/" className="kt-error_description">
							<i className="fas fa-chevron-left mr-2"></i>Volte para página inicial
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
