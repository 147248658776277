import React, { useEffect, useState } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import ButtonPWA from "../../components/ButtonPWA";
import Login from "./Login";
import SSORumo from "./SSORumo";

export default function AuthPage() {
	const [isIOS, setIsIOS] = useState(false);

	useEffect(() => {
		if (/mac/i.test(navigator?.platform) || /iPhone/.test(navigator?.platform)) {
			setIsIOS(true);
		}
	}, []);

	return (
		<>
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
					<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile bg-white">
						<div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
							style={{ backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-3.jpg')})` }}>
							<div className="kt-grid__item d-flex justify-space-between">
								<Link to="/" className="kt-login__logo" style={{ display: 'inline' }}>
									<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ maxWidth: '50%', height: 'auto' }} />
								</Link>

								<div className="d-lg-none d-inline">
									<ButtonPWA color="#67666e" />
								</div>
							</div>

							<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
								<div className="kt-grid__item kt-grid__item--middle">
									<h3 className="kt-login__title">Olá!</h3>
									<h4 className="kt-login__subtitle">
										Para começar, entre com o login e senha.
									</h4>
								</div>
							</div>

							<div className="kt-grid__item">
								<div className="kt-login__info">
									<div className="kt-login__copyright  d-lg-block d-none">&copy; 2022 KMM Tracking</div>

									<div className="kt-login__subtitle d-lg-none d-block">
										{isIOS &&
											<>
												<span>Para instalar o tracking siga os seguintes passos:</span>
												<li>Selecione o botão de compartilhar</li>
												<li>Encontre a informação de adicionar na tela inicial</li>
												<li>Por fim, clique em Adicionar</li>
											</>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
							<Switch>
								<Route path="/auth/login" component={Login} />
								<Route path="/auth/sso-rumo" component={SSORumo} />
								<Redirect from="/auth" exact={true} to="/auth/login" />
								<Redirect to="/auth/login" />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
